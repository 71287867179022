<template>
  <div>
    <center>
     <img src="elementos/bienvenidos.png" class="rounded" alt="Image" :style="isMobile ? 'width:50%;' : ''">
     <br>
     <img src="elementos/logo.png" class="rounded" alt="Image" :style="isMobile ? 'width:95%;' : ''">
     <br><br>
     <p v-if="perfil == 0" :style="isMobile ? 'font-size:16px; color:#ffffff;' : 'font-size:26px; color:#ffffff;'">
        Selecciona tu Canal para comenzar la misión</p>
      <p v-if="perfil == 3  || perfil == 1" :style="isMobile ? 'font-size:16px; color:#ffffff;' : 'font-size:26px; color:#ffffff;'">Selecciona tu Perfil para comenzar la misión</p><br>
       <p v-if="perfil == 4 || perfil == 5 || perfil == 2  || perfil == 6 || perfil == 7" style="font-size:16px; color:#ffffff;">Ingresa los siguientes datos:</p>
       <b-row class="justify-content-center mt-4">
         <b-col cols="10" md="3"   v-if="perfil == 0">
          <b-card  :style=" isMobile ? 'background-color:rgba(0, 0, 0, 0); border-color:#187abe;' :  'background-color:rgba(0, 0, 0, 0); border-color:#187abe; margin:-30px;'">
          <br>
            <b-card-text class="text-center">
              <b-row class="justify-content-center" >
                  <!-- <b-col cols="10" md="1"></b-col> -->
                  <b-col cols="12" md="10" >
                      <v-btn color="#5cb617" x-large block  style="text-transform:capitalize;"  @click="seleccionarCanal(3)">
                            <h4 style="color:#000;" class="text-center mt-1"><b>Propio</b></h4>
                      </v-btn>
                      <!-- <br>
                      <b-alert show variant="primary" style="background: #5cb617; border-color: #5cb617; border-radius: 10px; cursor: pointer; height: 70%;" id="caja">
                        <h3 style="color:#000;" class="text-center"><b>Propio</b></h3>
                      </b-alert> -->
                  </b-col>
                  <b-col cols="12" md="10"  class="mt-5">
                     <v-btn  color="#5cb617"   x-large block  style="text-transform:capitalize;"  @click="seleccionarCanal(1)">
                            <h4 style="color:#000;" class="text-center mt-1"><b>Retail</b></h4>
                      </v-btn>
                      <!-- <b-alert show variant="primary" style="background: #5cb617; border-color: #5cb617; border-radius: 10px; cursor: pointer;" id="caja">
                        <h3 style="color:#000;" class="text-center"><b>Retail</b></h3>
                      </b-alert> -->
                  </b-col>
                  <b-col cols="12" md="10"  class="mt-5">
                     <v-btn  color="#5cb617"   x-large block  style="text-transform:capitalize;"  @click="seleccionarCanal(2)" >
                            <h4 style="color:#000;" class="text-center mt-2"><b>TLMKT</b></h4>
                      </v-btn>
                      <!-- <b-alert show variant="primary" style="background: #5cb617; border-color: #5cb617; border-radius: 10px; cursor: pointer;" id="caja">
                        <h3 style="color:#000;" class="text-center"><b>TLMKT</b></h3>
                      </b-alert> -->
                  </b-col>
              </b-row>
              <br>
            </b-card-text>
          </b-card>          
         </b-col>
         <b-col cols="10" md="3"   v-if="perfil == 3">
          <b-card  :style=" isMobile ? 'background-color:rgba(0, 0, 0, 0); border-color:#187abe;' :  'background-color:rgba(0, 0, 0, 0); border-color:#187abe; margin:-30px;'">
          <br>
            <b-card-text class="text-center">
              <b-row class="justify-content-center" >
                  <!-- <b-col cols="10" md="1"></b-col> -->
                  <b-col cols="12" md="10" >
                     <v-btn  color="#5cb617"   x-large block  style="text-transform:capitalize;"  @click="seleccionarCanal(5)">
                            <h4 style="color:#000;" class="text-center mt-1"><b>Gerente / Ejecutivo</b></h4>
                      </v-btn>
                      <!-- <b-alert show variant="primary" style="background: #5cb617; border-color: #5cb617; border-radius: 10px; cursor: pointer;" id="caja">
                        <h3 style="color:#000;" class="text-center"><b>Gerente / Ejecutivo</b></h3>
                      </b-alert> -->
                  </b-col>
                  <b-col cols="12" md="10"  class="mt-5">
                     <v-btn  color="#5cb617"   x-large block  style="text-transform:capitalize;"  @click="seleccionarCanal(4)">
                            <h4 style="color:#000;" class="text-center mt-1"><b>Coordinador</b></h4>
                      </v-btn>
                      <!-- <b-alert show variant="primary" style="background: #5cb617; border-color: #5cb617; border-radius: 10px; cursor: pointer;" id="caja">
                        <h3 style="color:#000;" class="text-center"><b>Coordinador</b> </h3>
                      </b-alert> -->
                  </b-col>
              </b-row>
            </b-card-text>
            <br>
          </b-card>
         </b-col>
         <b-col cols="10" md="3"   v-if="perfil == 1">
          <b-card  :style=" isMobile ? 'background-color:rgba(0, 0, 0, 0); border-color:#187abe;' :  'background-color:rgba(0, 0, 0, 0); border-color:#187abe; margin:-30px;'">
          <br>
            <b-card-text class="text-center">
              <b-row class="justify-content-center" >
                  <b-col cols="12" md="10" >
                      <v-btn  color="#5cb617"   x-large block  style="text-transform:capitalize;" @click="seleccionarCanal(6)">
                            <h4 style="color:#000;" class="text-center mt-1"><b>Líder / Supervisor</b></h4>
                      </v-btn>
                      <!-- <b-alert show variant="primary" style="background: #5cb617; border-color: #5cb617; border-radius: 10px; cursor: pointer;" id="caja">
                        <h3 style="color:#000;" class="text-center"><b>Líder / Supervisor</b></h3>
                      </b-alert> -->
                  </b-col>
                  <b-col cols="12" md="10" class="mt-5">
                      <v-btn  color="#5cb617"   x-large block  style="text-transform:capitalize;"   @click="seleccionarCanal(7)">
                            <h4 style="color:#000;" class="text-center mt-1"><b>Punto de venta</b></h4>
                      </v-btn>
                      <!-- <b-alert show variant="primary" style="background: #5cb617; border-color: #5cb617; border-radius: 10px; cursor: pointer;" id="caja">
                        <h3 style="color:#000;" class="text-center"><b>Punto de venta</b></h3>
                      </b-alert> -->
                  </b-col>
              </b-row>
            </b-card-text>
            <br>
          </b-card>
         </b-col>
         <b-form  @submit="login" aria-autocomplete="false">
            <b-col cols="8" md="2"  v-if="perfil == 5">
                <b-form-input v-model="idpdv" placeholder="IDPDV"   type="text"  class=" text-center" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/>
                <b-form-input v-model="mrt" placeholder="AXC/MRT/CONTRASEÑA"   type="text"  class=" text-center mt-4" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/> 
                <b-form-input v-model="email" placeholder="Email"   type="text"  class=" text-center mt-4" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/>    
                <hr style="border-top: 5px solid #1b78b9 !important;">     
            </b-col>
            <b-col cols="8" md="2"  v-if="perfil == 4">
                <!-- <b-form-input v-model="idpdv" placeholder="IDPDV"   type="text"  class=" text-center" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/> -->
                <b-form-input v-model="mrt" placeholder="AXC/MRT/CONTRASEÑA"   type="text"  class=" text-center" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/> 
                <b-form-input v-model="email" placeholder="Email"   type="text"  class=" text-center mt-4" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/>    
                <hr style="border-top: 5px solid #1b78b9 !important;">     
            </b-col>
            <b-col cols="8" md="2"  v-if="perfil == 6">
                <b-form-input placeholder="Email" v-model="email"  type="text"  class=" text-center" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/>
                <b-form-input v-model="mrt" placeholder="MRT"   type="text"  class=" text-center mt-4" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/>
                <hr style="border-top: 5px solid #1b78b9 !important;">
            </b-col>
            <b-col cols="8" md="2"  v-if="perfil == 7">
                <b-form-input v-model="idpdv" placeholder="IDPDV"   type="text"  class=" text-center" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/>
                <b-form-input v-model="mrt" placeholder="Contraseña"   type="text"  class=" text-center mt-4" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/>
                <hr style="border-top: 5px solid #1b78b9 !important;">
            </b-col>
            <b-col cols="8" md="2"  v-if="perfil == 2">
                <b-form-input v-model="email" placeholder="Email"   type="text"  class=" text-center" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/>
                <b-form-input v-model="mrt" placeholder="CURP"   type="text"  class=" text-center mt-4" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/>
                <!-- <b-form-input placeholder="Agencia"   type="text"  class=" text-center mt-3" style="border-radius: 10px; background: #5cb617; border-color: #5cb617;"/> -->
                <hr style="border-top: 5px solid #1b78b9 !important;">
            </b-col>
            <b-col cols="12" md="12" >            
                <b-button  v-if="perfil == 4 || perfil == 5 || perfil == 2 ||  perfil == 6 || perfil == 7"  type="submit" variant="primary" class="mt-5">Acceder  <b-spinner v-if="loader" small></b-spinner>    </b-button> <br><br>
                <u v-if="perfil == 2 || perfil == 1 || perfil == 3" style="color:#fff;  cursor: pointer;" @click="seleccionarCanal(0)">Seleccionar otro canal</u>
                <u v-if="perfil == 4 || perfil == 5" style="color:#fff;  cursor: pointer;" @click="seleccionarCanal(3)">Seleccionar otro perfil</u>
                <u v-if=" perfil == 6 || perfil == 7" style="color:#fff;  cursor: pointer;" @click="seleccionarCanal(1)">Seleccionar otro perfil</u>
                <div class="text-center" v-if="perfil != 0">
                    <p class="text-danger mt-5" v-if="errors.includes( 'sesion' )"><b>Password y/o email incorrectos.</b></p>
                    <a href="https://asistentemovistar.com.mx/registro" target="_blank">
                      <b-button  v-if="perfil == 5 && val == 1 "    variant="primary" class="mt-1" :style="isMobile ? 'width: 80%; border-radius: 10px;' : 'width: 18%; border-radius: 10px;'"> 
                      Regístrate</b-button>
                    </a>
                </div>
            </b-col>
         </b-form>
       </b-row>   
       <!-- <p class="text-white" v-if="perfil == 0">Si presentas problemas con tu ingreso; <u style="color:#5cb617; cursor: pointer;" @click="abrirContacto()">Contáctanos</u></p> -->
     </center>

    <b-modal id="contactoAyuda" title=" " hide-footer hide-header size="lg" :hideHeaderClose="true" :cancelDisabled="true" body-bg-variant="dark">
        <div class="text-center text-white" >            
          <img src="elementos/logo.png" class="rounded" alt="Image" :style="isMobile ? 'width:95%;' : 'width:60%;'">
          <template v-if="!enviar">
            <p class="mt-4" style="font-size:16px;">Queremos resolver tu incidencia. Agrega los siguientes datos y describe tu problema.</p>
            <b-form  @submit="ayuda" aria-autocomplete="false">
              <b-row class="justify-content-center mt-4" >
                <b-col cols="11">
                  <select class="form-control text-center" v-model="canal">
                    <option value="0" selected>Seleccione el canal al que pertenece</option>
                    <option value="1">Retail</option>
                    <option value="2">TLMKT</option>
                    <option value="3">Propio</option>
                  </select>
                    <div class="text-center mt-2">
                      <p class="text-danger" v-if="errors.includes( 'canal' )"><b>Seleccione canal.</b></p>
                    </div>
                </b-col>     
                <b-col cols="11">
                  <b-form-input v-model="correo" placeholder="Email"   type="text"  class=" mt-2 text-center" />
                    <div class="text-center mt-2">
                      <p class="text-danger" v-if="errors.includes( 'email' )"><b>Ingrese email.</b></p>
                    </div>
                </b-col>  
                <b-col cols="11">
                  <b-form-textarea
                      id="textarea"
                      v-model="descripcion"
                      placeholder="Cuéntanos qué es lo que sucede. Recuerda agregar tus datos de ingreso completos"
                      rows="4"
                      max-rows="6"
                      class=" mt-2 text-center"
                  ></b-form-textarea>
                  <div class="text-center mt-2">
                    <p class="text-danger" v-if="errors.includes( 'descripcion' )"><b>Ingresa descripción.</b></p>
                  </div>
                </b-col>  
                <b-col cols="8">
                    <v-btn  color="#5cb617"  class="mt-4" block dark style="text-transform:capitalize;" type="submit">
                      Enviar <b-spinner v-if="loader" small></b-spinner> 
                    </v-btn>
                    <br>
                    <p><u style="cursor: pointer; font-size:19px;" @click="cerrarModal()">Cerrar</u></p>
                </b-col>
              </b-row>
            </b-form>
          </template>
          <template v-else>
              <b-row class="justify-content-center">
                <b-col cols="10" md="9">
                  <br>
                  <b-card  style="background-color:rgba(0, 0, 0, 0);">
                  <br v-if="!isMobile">
                  <b-card-text class="text-center">
                    <center>
                      <img src="elementos/check.png" class="rounded" alt="Image" :style="isMobile ? 'width:30%;' : 'width:20%;'">
                    </center>
                      <p class="text-white mt-3" :style="isMobile ? 'font-size:37px;' : 'font-size:40px;'">
                            <b style="color:#5cb617;">Listo</b> <br>
                            
                      </p>  
                      <span :style="isMobile ? 'font-size:15px; color:#fff;' : 'font-size:20px; color:#fff;'">Tu mensaje se ha enviado exitosamente</span><br><br>  
                      <v-btn  color="#5cb617"  class="mt-4"  dark style="text-transform:capitalize; width: 30%;" @click="cerrarModal()">
                        OK
                      </v-btn>
                  </b-card-text>
                  <br v-if="!isMobile"> 
                  </b-card>
                </b-col>
              </b-row>
          </template>
        </div>
    </b-modal>
  </div>
</template>

<script>
  // import Multiselect from 'vue-multiselect'
export default {
  name: 'Inicio',
  //  components: { Multiselect },
  data(){
    return{
      isMobile: this.$mobile(),
      perfil:0,
      idpdv:"",
      email:"",
      mrt:"",
      errors:[],
      loader:false,
      agencia:0,
      cadena:0,
      arrayAgencia:[],
      arrayCadena:[],
      arrayPdv:[],
      canal:0,
      correo:"",
      descripcion:"",
      options: [
          { value: 0, text: 'Seleccione el canal al que pertenece' },
          { value: 1, text: 'Retail' },
          { value: 2, text: 'TLMKT' },
          { value: 3, text: 'Propio' },
        ],
      enviar:false,
      val:0
    }
  },
  computed:{
      listadoCadena(){

        if (this.cadena == 0) {          
            return this.arrayPdv;
        }else{
               return   this.arrayPdv.filter(item => {
                    return  item.agencia?.toLowerCase().includes(this.agencia.toLowerCase()) &&
                            item.cadena?.toLowerCase().includes(this.cadena.toLowerCase()) 
                    ;
              });
        }
      }
  },
  methods:{
    seleccionarCanal(id){
       this.perfil = id;
      this.idpdv = "";
      this.mrt = "";
      this.agencia = 0;
      this.cadena = 0;
      this.errors = [];
      this.email = "";
      this.val = 0;
    },
    login(e) {
       e.preventDefault();
        this.errors = [];

    
        let model = this.prepareModel()

        this.loader = true;
        let request = this.$api.post("auth/login", model);
        request.then(
            response => {   
                console.log(response)      
                this.loader = false;  
                
                    if (response.data.sesion == 0)
                    {
                        this.val = response.data.val;
                        this.mrt = "";
                        this.errors.push('sesion');       
                    }else{
                        // this.clear(); mrt
                        this.$store.dispatch("setLogin", response.data);
                        // location.reload();
                        if (response.data.user_data.bienvenida == 0){
                         this.$router.push("/bienvenida");
                        }else{
                         this.$router.push("/"); 
                        }
                        
                        
                    }
                
            },
            error => {
                this.loader = false;
                console.log(error);
            }
        )
            
    },
    prepareModel() {
        let model = new FormData();
        model.append("idpdv", this.idpdv)
        model.append("email", this.email)
        model.append("mrt", this.mrt)
        model.append("cadena", this.cadena)
        model.append("agencia", this.agencia)
        model.append("perfil", this.perfil)
        return model;
    },
    selectores(){
      var url= 'auth/selectores';
        this.$api.get(url).then(
            ({data}) => {
                console.log(data);
                this.arrayAgencia = data.agencia;    
                this.arrayCadena = data.cadena;   
                this.arrayPdv = data.pdv;   
            }
        );
    },
    abrirContacto(){
      this.$bvModal.show('contactoAyuda');
    },
    cerrarModal(){
      this.correo = "";
      this.descripcion = "";
      this.canal = 0;
      this.errors = [];
      this.$bvModal.hide('contactoAyuda');
    },
    ayuda(e) {
       e.preventDefault();
        this.errors = [];
        let model = this.prepareModel2()
        
        if(this.canal == 0){
            this.errors.push('canal');     
        }else if (!this.correo){
            this.errors.push('email');       
        }else if(!this.descripcion){
            this.errors.push('descripcion');
        }else{
            this.loader = true;
            let request = this.$api.post("auth/ayuda", model);
            request.then(
                response => {   
                    console.log(response)      
                    this.loader = false;  
                    this.canal = 0;
                    this.correo = "";
                    this.descripcion = ""; 
                    this.enviar = true;          
                },
                error => {
                    this.loader = false;
                    console.log(error);
                }
            )
        }
    },
    prepareModel2() {
        let model = new FormData();
        model.append("canal", this.canal)
        model.append("correo", this.correo)
        model.append("descripcion", this.descripcion)
        return model;
    },
  },
  // mounted(){
  //   this.selectores();
  // }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>